import * as React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const PreventativeHealthServices = () => (
  <Layout>
    <SEO
      title="Preventative Health Services"
      description="Keeping your animals healthy through optimising timing and intervention is our priority! Remove the guesswork, we’ve got this!"
    />
    <section>
      <div className="container">
        <h1 className="section-heading">Preventative Health Services</h1>
        <div className="row">
          <div className="col-lg-6">
            <p>
              Keeping your animals healthy through optimising timing and
              intervention is our priority! Let Lakeside Lifestyle Animal Health
              help to prevent easily preventable diseases and provide excellent
              animal husbandry. Remove the guesswork with our tailored animal
              health plans and technical services, including;
            </p>
            <ul>
              <li>Vaccinations</li>
              <li>Internal and external parasite management</li>
              <li>Hoofcare</li>
              <li>Shearing, crutching and dagging of sheep and camelids</li>
              <li>Calf and kid disbudding</li>
              <li>Mineral management</li>
              <li>Feed budgeting</li>
              <li>Facial Eczema prevention</li>
              <li>And much much more!</li>
            </ul>
            <p className="mb-0">
              <Link to="/contact">Contact us</Link> with any questions or for
              further information.
            </p>
          </div>
          <div className="col-lg-6 mt-4 mt-lg-0 d-flex align-items-center">
            <StaticImage
              aspectRatio={16 / 10}
              src="../../images/piglets.jpg"
              alt="cow"
              loading="eager"
            />
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default PreventativeHealthServices
